<template>
  <div>
    <el-card style="margin-top: 10px;">
      <div class="itemPut">
        <div style="margin-top: 20px;"></div>
        <div class="headline">门店信息</div>
        <div class="across"></div>
        <div style="margin: 20px"></div>

        <el-form label-width="160px" :label-position="labelPositiona" :rules="rules" ref="storeFrom" :model="storeFrom">
          <div style="margin-left: 70px;">
            <el-form-item label="注册号码：" prop="mobile">
              <el-input v-model="storeFrom.mobile"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称：" prop="name">
              <el-input v-model="storeFrom.name"></el-input>
            </el-form-item>
            <el-form-item label="店铺详情地址：" prop="address">
              <el-input v-model="storeFrom.address"></el-input>
            </el-form-item>
            <el-form-item label="门头照：" prop="frontImg">
              <el-upload name="image" :action="imageFile" list-type="picture-card" :on-remove="handleRemove"
                :on-success="frontImg" :headers="headers" :on-preview="handlePictureCardPreview" :limit='1'
                :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="店内照：" prop="innerImg">
              <el-upload name="image" :action="imageFile" list-type="picture-card" :on-remove="handleRemove"
                :on-success="innerImg" :headers="headers" :on-preview="handlePictureCardPreview" :limit='1'
                :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="联系人：" prop="contact">
              <el-input v-model="storeFrom.contact"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
              <el-input v-model="storeFrom.phone"></el-input>
            </el-form-item>
            <el-form-item label="分店数量：" prop="subCount">
              <el-input type="number" oninput="if(value>999)value=999" v-model="storeFrom.subCount"></el-input>
            </el-form-item>
            <el-form-item label="合作期限：" style="margin-left: 10px;" prop="period">
              <el-select style="width: 250px;margin-left: -10px;" v-model="storeFrom.period">
                <el-option label="一年" value="1"></el-option>
                <el-option label="二年" value="2"></el-option>
                <el-option label="三年" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="BD邀请码：" prop="invokeCode">
              <el-input v-model="storeFrom.invokeCode"></el-input>
            </el-form-item>
          </div>

          <div class="headline">资质信息</div>
          <div class="across" style="margin-bottom:20px;"></div>
          <div style="margin-left: 70px;">
            <el-form-item style="margin-left: 10px;" label="主体类型：">
              <el-radio-group style="margin-left: -10px;" v-model="storeFrom.licenseData.type" @change="cervicaltrue(storeFrom.licenseData.type)">
                <el-radio :label="2">企业</el-radio>
                <el-radio :label="4">个体经商户</el-radio>
                <el-radio :label="2500">个人卖家</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="storeFrom.licenseData.type!=2500">
              <el-form-item    label="营业执照："  prop="licenseData.licenseImg" ref="uploads">
                <el-upload   name="licenseImg"  list-type="picture-card" ref="license"
                  :on-remove="licenseRemove" :action="licenseFile"  :on-success="licenseImg" :limit="1" :on-exceed="exceed" :headers="headers"
                  :on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload" >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item  label="注册号：" prop="licenseData.certNo">
                <el-input  v-model="storeFrom.licenseData.certNo"></el-input>
              </el-form-item>
              <el-form-item  label="公司名称：" prop="licenseData.name" :rules="[
                          { required: true, message: '请填写商户名称', trigger: 'blur' },
                        ]">
                <el-input v-model="storeFrom.licenseData.name" ></el-input>
              </el-form-item>
              <el-form-item label="注册地址："  prop="licenseData.address" :rules="[
                          { required: true, message: '请填写注册地址', trigger: 'blur' },
                        ]">
                <el-input v-model="storeFrom.licenseData.address"></el-input>
              </el-form-item>
              <el-form-item  label="法定代表人：" prop="licenseData.legalRepName">
                <el-input  v-model="storeFrom.licenseData.legalRepName"></el-input>
              </el-form-item>
              <el-form-item label="营业期限：" v-if="storeFrom.licenseData.type==2" style="margin-left: 10px;" prop="licenseData.scope">
                <el-radio-group style="margin-left: -10px;" v-model="storeFrom.licenseData.scope" @change="businesstrue(storeFrom.licenseData.scope)">
                  <el-radio :label="1">长期有效</el-radio>
                  <el-radio :label="2">固定有效期</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="storeFrom.licenseData.scope == 1 && storeFrom.licenseData.type==2">
              <el-form-item prop="licenseData.validTermStart" :rules="[{ required: true, message: '请填写开始日期', trigger: 'blur' },]">
                <el-date-picker
                  v-model="storeFrom.licenseData.validTermStart"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="开始日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div v-if="storeFrom.licenseData.scope == 2 && storeFrom.licenseData.type==2">
              <el-form-item prop="licenseData.validTermStart"  :rules="[{ required: true, message: '请填写开始日期', trigger: 'blur' },]">
                <el-date-picker
                  v-model="storeFrom.licenseData.validTermStart"
                  type="date"
                  placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                  </el-form-item>
                  <el-form-item prop="licenseData.validTermEnd" :rules="[{ required: true, message: '请填写结束日期', trigger: 'blur' },]">
                <el-date-picker
                  v-model="storeFrom.licenseData.validTermEnd"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="结束日期"
                >
                </el-date-picker>
                </el-form-item>
            </div>
              <div>
                <el-form-item style="margin-left: 10px;" label="其他资质："
                  prop="licenseData.licenseAddition">
                  <el-upload  name="image" :action="imageFile" list-type="picture-card" :on-remove="aptitudeRemove"
                    :on-success="aptitudeFile" :headers="headers" :on-preview="handlePictureCardPreview" :limit='9'
                    :before-upload="beforeAvatarUpload" :multiple="true">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </div>
            </div>
          </div>


          <div class="headline">结算信息</div>
          <div class="across" style="margin-bottom: 20px"></div>
          <div style="margin-left: 70px;">
            <el-form-item style="margin-left: 10px;" label="开店人身份信息：" ref="uploadElement" prop="file">
              <div>面部在前国徽在后</div>
              <el-upload style="margin-left: -10px;" multiple :limit="2" :action="idcardFile" list-type="picture-card" :on-remove="handleRemove"
                :on-change="checkFile" :auto-upload="false" :http-request="uploadFile" ref="upload"
                :on-exceed="handleExceed" :on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl"  alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="姓名：" prop="accountData.realName">
              <el-input v-model="storeFrom.accountData.realName"></el-input>
            </el-form-item>
            <el-form-item label="身份证：" prop="accountData.cardNo">
              <el-input v-model="storeFrom.accountData.cardNo"></el-input>
            </el-form-item>
            <el-form-item label="开户行：" prop="accountData.bankName">
              <el-select style="color:red" v-model="storeFrom.accountData.bankName">
                <el-option v-for="item in BankNameList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行卡号：" prop="accountData.bankCardNo">
              <el-input v-model="storeFrom.accountData.bankCardNo"></el-input>
            </el-form-item>
            <el-form-item label="银行预留手机号：" prop="accountData.reservationMobile">
              <el-input v-model="storeFrom.accountData.reservationMobile"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" style="display: flex;justify-content: center;">
          <router-link to="/store/list">
            <el-button>返 回</el-button>
          </router-link>
          <el-button type="primary" style="margin-left: 50px" @click="storeAdd('storeFrom')">提 交</el-button>
        </div>
      </div>

    </el-card>
  </div>
  <!-- 添加 -->
</template>
<script>
  import url from "@/utils/file";
  import {ocrIdcard,license} from "@/api/file";
  import {storeAdd,BankAccounts,storeEdit} from "@/api/store";
  import {getStore} from "@/utils/storage";
  import debounce from "../../utils/debounce"
  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("手机号不能为空"));
        } else {
          const reg = /^1[3-9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      };
      var validateIdNumber = (rule, value, callback) => {
        const idNumberReg =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
        if (!value) {
          return callback(new Error('请填写身份证号'))
        }
        setTimeout(() => {
          if (!idNumberReg.test(value)) {
            return callback(new Error('您的身份证号码格式错误!'))
          } else {
            callback()
          }
        }, 100)
      }
      var bankCards = (rule, value, callback) => {
        const idNumberReg = /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/
        if (!value) {
          return callback(new Error('请填写银行卡号'))
        }
        setTimeout(() => {
          if (!idNumberReg.test(value)) {
            return callback(new Error('您的银行卡号码格式错误!'))
          } else {
            callback()
          }
        }, 100)
      }
      var subCount = (rule, value, callback) => {
        const idNumberReg = /^[+]{0,9}(\d+)$/
        if (!value) {
          return callback(new Error('请输入分店数量'))
        }
        setTimeout(() => {
          if (!idNumberReg.test(value)) {
            return callback(new Error('您的分店数量格式错误!'))
          } else {
            callback()
          }
        }, 100)
      }
      var id = (rule, value, callback) => {}
      return {
        labelPositiona: "right",
        dialogVisible: false,
        cervical: true, //主体类型隐藏
        scope: 2, //判断营业期限条件
        business: true, //营业期限
        businesss: false, //营业期限
        cardNoperiod: 2, //判断身份证有效期限条件
        cardNoperiods: true, //份证有效期限
        cardNoperiodss: false, //份证有效期限
        supervisor: false, //管理员信息同步 按钮
        idcardInfo: "", //身份证识别信息
        storeFrom: {
          period: "1", //合作期限
          frontImg: [], //门头照
          innerImg: [], //店内照
          licenseData: {
            certNo: "", //注册号
            name: "", //商户名称
            address: "", //住所
            legalRepName: "", //法人
            validTermStart: "", //营业结束日期
            validTermEnd: "", //营业开始日期
            type: 2500,
            scope:1,
            licenseAddition:[],//其他资质
            licenseImg: "",
          },
          accountData: {
            address: "", //详情地址
            bankCardNo: "", //银行卡号
            bankName: "", //开户行
            birthDay: "", //出生日期
            cardFrontSide: "", //身份证正面
            cardNo: "", //身份证号
            cardReverseSide: "", //身份证反面
            createTime: "", //创建时间
            gender: "", //性别
            id: 0, //主键id
            issuedBy: "", //发证机关
            nation: "", //民族
            realName: "", //结算人姓名
            reservationMobile: "", //预留电话
            storeId: 0, //店铺id
            validityEnd: "", //身份证有效期结结束
            validityStart: "", //身份证有效期开始
            verifyId: "" //e签宝业务id
          },
        },
        imageFile: url.url_image, //上传image
        licenseFile: url.url_license, //营业执照
        idcardFile: url.url_idcard, //身份证
        BankNameList: [], //开户行选项
        fileData:[],
        rules: {
          mobile: [{required: true,validator: checkPhone, trigger: "blur"}],
          name: [{required: true,message: "请填写店铺名称",trigger: "blur"}],
          address: [{required: true,message: "请填写店铺地址",trigger: "blur"}, ],
          frontImg: [{required: true,   message: "请上传门头照", trigger: "change",}, ],
          innerImg: [{ required: true,message: "请上传店内照",trigger: "change",}, ],
          contact: [{required: true,message: "请填写店铺联系人",trigger: "blur"}, ],
          phone: [{required: true,validator: checkPhone,trigger: "blur"}],
          subCount: [{required: true,validator: subCount,trigger: "blur"}, ],
          invokeCode: [{required: true,message: "请填写BD邀请码",trigger: "blur"}, ],
          accountData: {cardNo: [{required: true,validator: validateIdNumber,trigger: "blur"}, ],
          bankCardNo: [{required: true,validator: bankCards,trigger: "blur"}, ],
          reservationMobile: [{required: true,validator: checkPhone,trigger: "blur"}, ],
          bankName: [{required: true,message: "请选择开户行",trigger: "change"}],
          realName: [{required: true,message: "请输入姓名",trigger: "blur"}],},
          licenseData: {
            legalRepName: [{required: true,message: "请填写法人姓名",trigger: "blur"}, ],
            certNo: [{required: true,message: "请填写营业执照注册号",trigger: "blur",}, ],
            name: [{required: true,message: "请填写商户名称",trigger: "blur"}, ],
            licenseImg:[{ required: true,message: "请上传营业执照",trigger: "change",}, ],
          }
        },
        submitFile: {
          arrFile: [],
        },
        licenseImgNum: 0,
        dialogImageUrl: "", //照片放大
      };
    },
    mounted() {
      //开户行选项
      BankAccounts().then(res => {
        this.BankNameList = res.data
      })
    },
    methods: {
      handleRemove(file, fileList) {
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      //营业执照
      licenseImg(img) {
      this.rules.file = [{
        required: true,
        message: '请上传营业执照'
      }]
      if (img.data!='') {
        this.rules.licenseImg = []
        this.$nextTick(()=>{
        this.$refs['uploads'].clearValidate()         
        })

      }
      // 获取上传图片url截取？后面加密
      this.storeFrom.licenseData.licenseImg = img.data.license.substring(
        0,
        img.data.license.lastIndexOf("?")
      );
      let { certNo, name, address, legalRepName } = img.data.licenseInfo;
        this.storeFrom.licenseData.certNo = certNo;
      this.storeFrom.licenseData.name = name;
      this.storeFrom.licenseData.address = address;
      this.storeFrom.licenseData.legalRepName = legalRepName;
      },

      //删除营业执照删除
      licenseRemove(file) {
        this.storeFrom.licenseData.licenseImg=""
      },
      // 资质信息判断隐藏显示
    cervicaltrue(row) {
      let self = this;
      if (row === 2500) {
        self.cervical = false;
        self.storeFrom.licenseData.validTermStart = "长期"
        self.storeFrom.licenseData.validTermEnd = "长期";
      } else if (row===2) {
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = ""
        self.storeFrom.licenseData.validTermEnd = "";
      } else if (row===4){
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = "长期"
        self.storeFrom.licenseData.validTermEnd = "长期";
      }
    },
    //营业期限显示隐藏
    businesstrue(row) {
      let self = this;
      self.business = !self.business;
      self.businesss = !self.businesss;
      if (row === 1) {
        self.storeFrom.licenseData.validTermEnd = "长期";
        self.storeFrom.licenseData.validTermStart = "";
      } else if (row === 2) {
        self.storeFrom.licenseData.validTermStart = "";
        self.storeFrom.licenseData.validTermEnd = "";
      }
    },
      handleRemove(file, fileList) {},
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      //门头照
      frontImg(img) {
        let self = this;
        self.storeFrom.frontImg = img.data;
      },
      //店内照
      innerImg(img) {
        let self = this;
        self.storeFrom.innerImg = img.data;
      },

      //身份证有效期
      cardNotrue(row) {
        let self = this;
        self.cardNoperiods = !self.cardNoperiods;
        self.cardNoperiodss = !self.cardNoperiodss;
        if (row === 1) {
          self.storeFrom.accountData.validityEnd = "长期";
        } else if (row === 2) {
          self.storeFrom.accountData.validityEnd = "";
        }
      },

      //上传图片个数为2个走submit提交
      checkFile(file, fileList) {
        this.rules.file = [{
          required: true,
          message: '请上传身份证正反面'
        }]
        if (fileList.length != 0 && fileList.length != 1) {
          this.rules.file = []
          this.$nextTick(()=>{
             this.$refs['uploadElement'].clearValidate()
          })
        }
        let count = fileList.length;
        if (count === 2) {
          this.$refs.upload.submit();
        }
      },
      //上传图片个数超过了limit最大数给出提示
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
        );
      },
      handleExceeds(files, fileList){
        this.$message.warning(
          `当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
        );
      },
      //判断营业大于两张
      exceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件，请删除重新上传`
        );
      },
      // 上传大小限制
      //限定上传大小
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
        }
        return isLt2M;
      },
      //其他资质上传图片
    aptitudeFile(img) {
      this.storeFrom.licenseData.licenseAddition.push(img.data);
    },
    //其他资质删除图片
    aptitudeRemove(file, fileList) {
      let i = this.storeFrom.licenseData.licenseAddition.indexOf(file.url);
      this.storeFrom.licenseData.licenseAddition.splice(i, 1);
    },
       //上传文件大于两个阻止 两张就走接口
      uploadFile(param) {
        this.submitFile.arrFile.push(param.file);
        if (this.submitFile.arrFile.length != 2) {
          return;
        }
        let formData = new FormData();
        formData.append("frontImg", this.submitFile.arrFile[0]);
        formData.append("reverseImg", this.submitFile.arrFile[1]);
        ocrIdcard(formData).then((res) => {
          let frontImg = res.data.front.substring(
            0,
            res.data.front.lastIndexOf("?")
          );
          let reverseImg = res.data.reverse.substring(
            0,
            res.data.reverse.lastIndexOf("?")
          );

          //身份证正反面
          this.storeFrom.accountData.cardFrontSide = frontImg;
          this.storeFrom.accountData.cardReverseSide = reverseImg;
          //识别图片信息
          this.idcardInfo = res.data.idcardInfo;
          this.storeFrom.accountData.realName = this.idcardInfo.name;
          this.storeFrom.accountData.cardNo = this.idcardInfo.idNo;
          var res = this.idcardInfo.validityPeriod;
          let identitystor = res.substring(0, res.indexOf("-"));
          let identityend = res.substring(21, res.lastIndexOf("-") + 1);
          let str = res.substring(res.lastIndexOf("长"));
          if (str === "长期") {
            this.cardNoperiod = 1;
            this.cardNoperiods = false;
            this.cardNoperiodss = true;
            this.storeFrom.accountData.validityStart = identitystor;
          } else {
            this.cardNoperiod = 2;
            this.cardNoperiods = true;
            this.cardNoperiodss = false;
            this.storeFrom.accountData.validityStart = identitystor;
            this.storeFrom.accountData.validityEnd = identityend;
          }
        });
      },
      //店铺添加
      storeAdd(storeFrom) {
        this.rules.file = [{
          required: true,
          message: '请上传身份证正反面'
        }]
        if (this.submitFile.arrFile.length != 0) {
          this.rules.file = []
          this.$nextTick(()=>{
          this.$refs['uploadElement'].clearValidate()
          })

        }
        this.rules.licenseImg = [{
          required: true,
          message: '请上传营业执照'
        }]
        if (this.licenseFile!=[]) {
          this.rules.licenseImg = []
          this.$nextTick(()=>{
            this.$refs['uploads'].clearValidate()
          })
        }
        this.$refs[storeFrom].validate((valid) => {
          if (valid == true) {
            storeAdd(this.storeFrom).then((res) => {
              if (res.status === 10000 && res.data === true) {
                this.$router.push("/store/list");
              }
            });
          }
        });
      },
    },
    computed: {
      //图片上传请求头部
      headers() {
        return {
          Authorization: getStore("token"),
        };
      },
    },
  };
</script>
<style lang="less" scoped>
  @import "../../assets/css/overall.less";

  i {
    margin-left: 0px;
  }

  .itemPut {
    margin-left: 40px;
  }
</style>
<style lang="less">
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 146px;
    height: 146px;
    line-height: 146px;
    vertical-align: top;
  }

  .headline {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }

  .hide .el-upload--picture-card {
    display: none;
  }

  .el-form-item__label {
    text-align: left;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
</style>
